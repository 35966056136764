/* You can add global styles to this file, and also import other style files */
$colors: (
  primary: #1abc9c,
  light: #ecf0f7,
  danger: #ff2721,
  warning: #ffb921,
  success: #21ce99,
  greylight: #8a8da2,
  dark: #333333
);
@import '../../../src/styles';

@import './app/app.component';
@import './app/protected/candidates/show/header/candidates.show.header.component';
